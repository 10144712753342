import React, { useState } from 'react';
import { auth } from '../firebaseConfig';
import { 
  signInWithEmailAndPassword, 
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider 
} from 'firebase/auth';

function Login() {
  const [isRegister, setIsRegister] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Erreur lors de la connexion Google:", error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isRegister) {
      try {
        await createUserWithEmailAndPassword(auth, email, password);
      } catch (error) {
        console.error("Erreur lors de l'inscription :", error.message);
      }
    } else {
      try {
        await signInWithEmailAndPassword(auth, email, password);
      } catch (error) {
        console.error("Erreur lors de la connexion :", error.message);
      }
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-900 relative">
      {/* Image en background */}
      <div className="absolute inset-0 flex items-center justify-center opacity-10 pointer-events-none">
        <img 
          src="/rap-logo.png" 
          alt="" 
          className="w-[800px] h-[800px] object-contain"
        />
      </div>

      {/* Contenu */}
      <div className="relative z-10">
        {/* Titre en haut */}
        <div className="text-center py-8">
          <h1 className="text-6xl font-bold bg-gradient-to-r from-[#E242F9] to-[#4F93F0] bg-clip-text text-transparent font-bungee">
            RapGPT
          </h1>
          <p className="text-gray-400 text-xl mt-2">Ton assistant IA pour écrire tes textes de rap</p>
        </div>

        {/* Formulaire centré */}
        <div className="flex-1 flex items-center justify-center p-4">
          <div className="w-full max-w-md">
            <form onSubmit={handleSubmit} className="bg-gray-800/90 backdrop-blur-sm p-8 rounded-lg shadow-xl">
              <h2 className="text-2xl text-white mb-6 text-center">
                {isRegister ? "Créer un compte" : "Se connecter"}
              </h2>

              {/* Bouton Google */}
              <button
                type="button"
                onClick={handleGoogleLogin}
                className="w-full mb-6 py-2 px-4 rounded bg-white text-gray-800 font-semibold hover:bg-gray-100 transition-colors flex items-center justify-center gap-2 shadow-md"
              >
                <img 
                  src="/google-logo.png" 
                  alt="Google" 
                  className="w-5 h-5"
                />
                Continuer avec Google
              </button>

              {/* Séparateur */}
              <div className="relative mb-6">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-600"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-gray-800 text-gray-400">ou</span>
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-gray-300 mb-2">Email</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full p-2 rounded bg-gray-700 text-white border border-gray-600 focus:border-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500"
                  placeholder="Entrez votre email"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-300 mb-2">Mot de passe</label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="w-full p-2 rounded bg-gray-700 text-white border border-gray-600 focus:border-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500"
                  placeholder="Entrez votre mot de passe"
                />
              </div>
              <button
                type="submit"
                className="w-full py-2 px-4 rounded bg-gradient-to-r from-[#E242F9] to-[#4F93F0] text-white font-semibold hover:opacity-90 transition-opacity"
              >
                {isRegister ? "S'inscrire" : "Se connecter"}
              </button>
              <p className="text-center text-gray-400 mt-4">
                {isRegister ? "Déjà un compte ?" : "Pas encore de compte ?"}{" "}
                <span
                  onClick={() => setIsRegister(!isRegister)}
                  className="text-purple-400 cursor-pointer hover:text-purple-300"
                >
                  {isRegister ? "Se connecter" : "S'inscrire"}
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;