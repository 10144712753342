import React from 'react';

function ThemeInput({ theme, setTheme, onEnterPress }) {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && theme.trim()) {
      onEnterPress();
    }
  };

  return (
    <div className="mb-4 w-full">
      <input
        type="text"
        value={theme}
        onChange={(e) => setTheme(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Entrez un thème pour votre rap"
        className="rap-input w-full"
      />
    </div>
  );
}

export default ThemeInput;