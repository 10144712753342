import React from 'react';

function Welcome({ onGetStarted }) {
  return (
    <div className="min-h-screen flex flex-col bg-gray-900">
      {/* Titre en haut */}
      <div className="text-center pt-12 pb-8">
        <h1 className="text-7xl font-bold bg-gradient-to-r from-[#E242F9] to-[#4F93F0] bg-clip-text text-transparent font-bungee mb-4">
          RapGPT
        </h1>
        <p className="text-gray-400 text-2xl mb-12">Ton assistant IA pour écrire tes textes de rap</p>
        
        {/* Bouton au-dessus de l'image */}
        <button
          onClick={onGetStarted}
          className="px-12 py-4 text-xl rounded-full bg-gradient-to-r from-[#E242F9] to-[#4F93F0] text-white font-semibold hover:opacity-90 transition-all duration-300 transform hover:scale-105 shadow-lg"
        >
          C'est parti !
        </button>
      </div>

      {/* Image qui touche le bas */}
      <div className="flex-1 flex justify-center items-end">
        <img 
          src="/rap-logo.png" 
          alt="RapGPT Logo" 
          className="w-96 h-auto object-contain" 
        />
      </div>
    </div>
  );
}

export default Welcome; 