import React, { useState, useCallback, useEffect } from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ThemeInput from './components/ThemeInput';
import LyricsDisplay from './components/LyricsDisplay';
import GenerateButton from './components/GenerateButton';
import SavedLyrics from './components/SavedLyrics';
import SongBuilder from './components/SongBuilder';
import Welcome from './components/Welcome';
import Login from './components/Login';
import MesTextes from './components/MesTextes';
import { auth, db } from './firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { collection, addDoc, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import Toast from './components/Toast';

const API_URL = 'https://generatelyrics-ym2ggdbx7q-ew.a.run.app';
const API_URL_VLLM = 'https://europe-west1-rapgpt-f1e84.cloudfunctions.net/generateLyricsVllm';

// const API_URL = 'http://127.0.0.1:5001/rapgpt-f1e84/europe-west1/generateLyricsVllm';
console.log("API_URL:", API_URL);
console.log('ENV raw value:', process.env.REACT_APP_USE_VLLM);
console.log('ENV type:', typeof process.env.REACT_APP_USE_VLLM);
console.log('ENV comparison:', process.env.REACT_APP_USE_VLLM === 'true');

// Définir les sections ici
const SECTIONS = [
  { id: 'verse1', title: 'Couplet 1' },
  { id: 'chorus1', title: 'Refrain 1' },
  { id: 'verse2', title: 'Couplet 2' },
  { id: 'chorus2', title: 'Refrain 2' },
];

// Créer un mappage de l'ordre des sections
const SECTIONS_ORDER = SECTIONS.reduce((acc, section, index) => {
  acc[section.id] = index;
  return acc;
}, {});

function App() {
  const [theme, setTheme] = useState('');
  const [currentLyrics, setCurrentLyrics] = useState('');
  const [savedLyrics, setSavedLyrics] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [songBlocks, setSongBlocks] = useState([
    { id: 'verse1-1', content: '', type: 'verse1' },
    { id: 'chorus1-1', content: '', type: 'chorus1' },
    { id: 'verse2-1', content: '', type: 'verse2' },
    { id: 'chorus2-1', content: '', type: 'chorus2' },
  ]);
  const [user, setUser] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  const [toast, setToast] = useState({ show: false, message: '', type: 'success' });

  useEffect(() => {
    console.log("App component mounted");
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    console.log("Utilisateur actuel:", user);
  }, [user]);

  const generateLyrics = async (tokenCount = null, charLength = null) => {
    console.log("Generating lyrics...");
    setIsLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_USE_VLLM === 'true' 
        ? API_URL_VLLM
        : API_URL;
        
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'omit',
        body: JSON.stringify({ theme, tokenCount, charLength }),
      });
      
      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`);
      }
      
      console.log("Response received:", response);
      const data = await response.json();
      setIsLoading(false);
      return data.lyrics;
    } catch (error) {
      console.error('Erreur:', error);
      setIsLoading(false);
      throw error;
    }
  };

  const handleGenerateLyrics = async () => {
    const lyrics = await generateLyrics();
    setCurrentLyrics(lyrics);
  };

  // Fonction pour sauvegarder un fragment individuel
  const handleSaveFragment = () => {
    if (currentLyrics.trim() !== '') {
      setSavedLyrics([...savedLyrics, { 
        id: Date.now().toString(), 
        text: currentLyrics 
      }]);
      setCurrentLyrics('');
    } else {
      alert("Le fragment ne peut pas être vide.");
    }
  };

  // Fonction pour sauvegarder l'ensemble de la chanson
  const handleSaveSong = async (songTitle) => {
    if (!user) {
      showToast("Veuillez vous connecter pour sauvegarder vos textes.", "error");
      return;
    }

    const getSectionLabel = (type) => {
      const section = SECTIONS.find(sec => sec.id === type);
      return section ? section.title : '';
    };

    const sortedBlocks = songBlocks
      .filter(block => block.content.trim() !== '')
      .sort((a, b) => SECTIONS_ORDER[a.type] - SECTIONS_ORDER[b.type]);

    let songContent = '';
    let currentSection = null;

    sortedBlocks.forEach(block => {
      if (currentSection !== block.type) {
        currentSection = block.type;
        songContent += `[${getSectionLabel(block.type)}]\n`;
      }
      songContent += `${block.content}\n`;
    });

    try {
      await addDoc(collection(db, "users", user.uid, "songs"), {
        title: songTitle,
        theme,
        lyrics: songContent.trim(),
        createdAt: new Date()
      });
      showToast("Texte sauvegardé avec succès !");
    } catch (error) {
      console.error("Erreur lors de la sauvegarde:", error);
      showToast("Une erreur est survenue lors de la sauvegarde.", "error");
    }
  };

  // Fonction pour gérer le Drag and Drop
  const onDragEnd = useCallback(
    (result) => {
      const { destination, source, draggableId } = result;
      if (!destination) return;

      // Si la destination est la même que la source, ne rien faire
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      // Déplacer un fragment depuis 'lyrics' vers une section du constructeur
      if (source.droppableId === 'lyrics' && SECTIONS.some(sec => sec.id === destination.droppableId)) {
        // Trouver le fragment dans savedLyrics
        const fragment = savedLyrics.find(lyric => lyric.id === draggableId);
        if (!fragment) return;

        // Vérifier que le fragment n'est pas vide
        if (fragment.text.trim() === '') return;

        // Créer un nouveau bloc pour songBlocks avec le type de la section destination
        const newBlock = {
          id: `${destination.droppableId}-${Date.now().toString()}`, // Assurez-vous que les IDs sont uniques
          content: fragment.text,
          type: destination.droppableId,
        };

        // Ajouter le nouveau bloc à songBlocks
        const newSongBlocks = Array.from(songBlocks);
        newSongBlocks.splice(destination.index, 0, newBlock);
        setSongBlocks(newSongBlocks);

        // Optionnel : retirer le fragment de savedLyrics
        const newSavedLyrics = Array.from(savedLyrics);
        newSavedLyrics.splice(source.index, 1);
        setSavedLyrics(newSavedLyrics);

        return;
      }

      // Déplacement au sein de songBlocks ou entre sections
      const sourceIndex = songBlocks.findIndex(block => block.id === draggableId);
      if (sourceIndex === -1) return;

      const [movedBlock] = songBlocks.splice(sourceIndex, 1);
      movedBlock.type = destination.droppableId; // Mettre à jour le type si déplacé vers une autre section

      const newSongBlocks = Array.from(songBlocks);
      newSongBlocks.splice(destination.index, 0, movedBlock);
      setSongBlocks(newSongBlocks);
    },
    [savedLyrics, songBlocks]
  );

  // Fonction pour gérer la sauvegarde d'un fragment
  const handleSave = () => {
    if (currentLyrics.trim() !== '') {
      setSavedLyrics([...savedLyrics, { 
        id: Date.now().toString(), 
        text: currentLyrics 
      }]);
      setCurrentLyrics('');
    }
  };

  // Fonction pour gérer la déconnexion
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
    }
  };

  // Fonction pour remplacer un fragment
  const handleReplace = async (selectedText) => {
    try {
      setIsLoading(true);
      const response = await fetch(`${API_URL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ theme: theme || 'rap' }),
      });
      
      if (!response.ok) {
        throw new Error('Erreur lors de la génération');
      }
      
      const data = await response.json();
      setIsLoading(false);
      return data.lyrics;
    } catch (error) {
      console.error("Erreur lors du remplacement:", error);
      setIsLoading(false);
      return null;
    }
  };

  // Fonction pour supprimer un fragment
  const handleDelete = (id) => {
    setSavedLyrics(savedLyrics.filter(lyric => lyric.id !== id));
  };

  const showToast = (message, type = 'success') => {
    setToast({ show: true, message, type });
  };

  if (!user) {
    if (!showLogin) {
      return <Welcome onGetStarted={() => setShowLogin(true)} />;
    }
    return <Login />;
  }

  return (
    <div className="min-h-screen flex flex-col bg-gray-900 relative">
      <div className="absolute inset-0 flex items-center justify-center opacity-10 pointer-events-none">
        <img 
          src="/rap-logo.png" 
          alt="" 
          className="w-[800px] h-[800px] object-contain"
        />
      </div>

      <div className="relative z-10">
        <nav className="bg-gray-900/90 backdrop-blur-sm text-white py-3 px-6 shadow-lg border-b border-gray-800">
          <div className="container mx-auto flex justify-between items-center">
            <h1 className="text-3xl font-bold bg-gradient-to-r from-[#E242F9] to-[#4F93F0] bg-clip-text text-transparent font-bungee">
              RapGPT
            </h1>
            <div className="flex space-x-4">
              <Link 
                to="/" 
                className="font-bungee font-bold text-sm px-3 py-1 hover:text-[#E242F9] flex items-center"
              >
                Accueil
              </Link>
              <Link 
                to="/mes-textes" 
                className="font-bungee font-bold text-sm px-3 py-1 hover:text-[#E242F9] flex items-center"
              >
                Mes textes
              </Link>
              <button
                onClick={handleLogout}
                className="font-bungee font-bold text-sm px-3 py-1 rounded-md bg-transparent hover:bg-red-600 text-gray-300 hover:text-white border border-gray-600 hover:border-red-600 transition-all duration-300 flex items-center"
              >
                Déconnecter
              </button>
            </div>
          </div>
        </nav>

        <div className="container mx-auto p-6">
          <Routes>
            <Route path="/" element={
              <DragDropContext onDragEnd={onDragEnd}>
                <div className="rap-container flex flex-col md:flex-row">
                  <div className="rap-left-column w-full md:w-1/2 p-4">
                    <div className="mb-8">
                      <ThemeInput theme={theme} setTheme={setTheme} onEnterPress={handleGenerateLyrics} />
                      <GenerateButton onClick={handleGenerateLyrics} isLoading={isLoading} />
                      <LyricsDisplay 
                        lyrics={currentLyrics} 
                        onSave={handleSaveFragment} // Utiliser handleSaveFragment
                        onGenerateNew={handleGenerateLyrics} 
                      />
                    </div>
                    <div>
                      <h2 className="text-2xl font-bold mb-4 main-title-gradient">
                        Fragments sauvegardés
                      </h2>
                      <Droppable droppableId="lyrics">
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
                            {savedLyrics.map((lyric, index) => (
                              <Draggable key={lyric.id} draggableId={lyric.id} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className="rap-fragment-container bg-gray-800/90 backdrop-blur-sm p-2 rounded"
                                  >
                                    <div className="rap-fragment-content">
                                      <SavedLyrics 
                                        title={lyric.title}
                                        lyrics={lyric.text} 
                                        onReplace={handleReplace} 
                                      />
                                    </div>
                                    <div className="rap-fragment-buttons flex justify-between items-center mt-2">
                                      <div 
                                        {...provided.dragHandleProps} 
                                        className="cursor-move text-gray-300 text-xl p-2"
                                      >
                                        ☰
                                      </div>
                                      <button
                                        onClick={() => handleDelete(lyric.id)}
                                        className="supprimer-fragment rap-button rounded-full px-2 py-1" // Ajustement de la taille
                                        aria-label="Supprimer"
                                      >
                                        ✖
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                  <div className="rap-right-column w-full md:w-1/2 p-4">
                    <SongBuilder 
                      songBlocks={songBlocks} 
                      setSongBlocks={setSongBlocks} 
                      saveSongToDatabase={handleSaveSong} // Utiliser handleSaveSong
                    />
                  </div>
                </div>
              </DragDropContext>
            } />
            <Route path="/mes-textes" element={<MesTextes showToast={showToast} />} />
          </Routes>
        </div>
      </div>

      {toast.show && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ show: false, message: '', type: 'success' })}
        />
      )}
    </div>
  );
}

export default App;