import React from 'react';

function LyricsDisplay({ lyrics, onSave, onGenerateNew }) {
  return (
    <div className="rap-card">
      <h2 className="text-2xl font-bold mb-2 main-title-gradient">
        Paroles générées
      </h2>
      <pre className="rap-lyrics mb-4">{lyrics}</pre>
      {lyrics && (
        <div className="flex space-x-2">
          <button onClick={onSave} className="rap-button flex-1">
            <span role="img" aria-label="save">❤️</span> Garder
          </button>
          <button onClick={onGenerateNew} className="rap-button flex-1">
            <span role="img" aria-label="generate">🔄</span> Nouveau fragment
          </button>
        </div>
      )}
    </div>
  );
}

export default LyricsDisplay;