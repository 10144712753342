import React, { useState } from 'react';

function TitlePopup({ onSave, onCancel }) {
  const [title, setTitle] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title.trim()) {
      onSave(title);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full mx-4">
        <h3 className="text-xl font-bold mb-4 text-white">
          Donnez un titre à votre chanson
        </h3>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Titre de la chanson"
            className="w-full p-2 mb-4 rounded border border-purple-300 bg-gray-700 text-white"
            autoFocus
          />
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onCancel}
              className="rap-button bg-gray-600 hover:bg-gray-700"
            >
              Annuler
            </button>
            <button
              type="submit"
              disabled={!title.trim()}
              className="rap-button bg-purple-600 hover:bg-purple-700"
            >
              Sauvegarder
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default TitlePopup; 