import React, { useState, useRef } from 'react';

function SavedLyrics({ title, lyrics, onReplace }) {
  const [selectedText, setSelectedText] = useState('');
  const [manualInput, setManualInput] = useState('');
  const [showManualInput, setShowManualInput] = useState(false);
  const textRef = useRef(null);

  const handleTextSelection = () => {
    const selection = window.getSelection();
    const selected = selection.toString().trim();
    if (selected) {
      setSelectedText(selected);
    }
  };

  const handleReplace = async () => {
    if (selectedText) {
      const newText = await onReplace(selectedText);
      if (newText) {
        const fullText = textRef.current.innerText;
        const updatedText = fullText.replace(selectedText, newText);
        textRef.current.innerText = updatedText;
        setSelectedText('');
      }
    }
  };

  const handleManualReplace = () => {
    if (selectedText && manualInput) {
      const fullText = textRef.current.innerText;
      const updatedText = fullText.replace(selectedText, manualInput);
      textRef.current.innerText = updatedText;
      setSelectedText('');
      setManualInput('');
      setShowManualInput(false);
    }
  };

  return (
    <div className="h-full flex flex-col p-4 rap-card" style={{ backgroundColor: '#1a1f2d' }}>
      <pre 
        ref={textRef}
        className="rap-lyrics cursor-text text-gray-800 flex-grow overflow-auto mb-2"
        onMouseUp={handleTextSelection}
      >
        {lyrics}
      </pre>
      {selectedText && (
        <div className="mt-auto space-y-2">
          <button
            onClick={handleReplace}
            className="rap-button w-full bg-blue-600 hover:bg-blue-700"
          >
            Remplacer par l'IA
          </button>
          {!showManualInput ? (
            <button
              onClick={() => setShowManualInput(true)}
              className="rap-button w-full bg-green-600 hover:bg-green-700"
            >
              Remplacer manuellement
            </button>
          ) : (
            <div className="space-y-2">
              <input
                type="text"
                value={manualInput}
                onChange={(e) => setManualInput(e.target.value)}
                className="rap-input w-full bg-gray-100 text-gray-800"
                placeholder="Saisie manuelle"
              />
              <button
                onClick={handleManualReplace}
                className="rap-button w-full bg-green-600 hover:bg-green-700"
              >
                Confirmer le remplacement manuel
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SavedLyrics;