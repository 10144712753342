import React, { useState } from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import TitlePopup from './TitlePopup';

function SongBuilder({ songBlocks, setSongBlocks, saveSongToDatabase }) {
  const [showTitlePopup, setShowTitlePopup] = useState(false);
  
  const sections = [
    { id: 'verse1', title: 'Couplet 1' },
    { id: 'chorus1', title: 'Refrain 1' },
    { id: 'verse2', title: 'Couplet 2' },
    { id: 'chorus2', title: 'Refrain 2' },
  ];

  const handleDelete = (id) => {
    setSongBlocks(songBlocks.filter(block => block.id !== id));
  };

  const handleSaveClick = () => {
    setShowTitlePopup(true);
  };

  const handleSaveConfirm = (title) => {
    saveSongToDatabase(title);
    setShowTitlePopup(false);
  };

  return (
    <div className="song-builder h-full flex flex-col">
      <h2 className="text-3xl font-bold mb-4 main-title-gradient">
        Constructeur de chanson
      </h2>
      
      {/* Bouton Sauvegarder */}
      <button
        onClick={handleSaveClick}
        className="rap-button mb-4 bg-blue-600 hover:bg-blue-700"
      >
        Sauvegarder le texte
      </button>

      {showTitlePopup && (
        <TitlePopup
          onSave={handleSaveConfirm}
          onCancel={() => setShowTitlePopup(false)}
        />
      )}

      <div className="song-builder-content flex-grow overflow-y-auto">
        {sections.map((section) => (
          <Droppable key={section.id} droppableId={section.id}>
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="mb-4 p-4 border-2 border-purple-300 rounded-lg bg-purple-50"
              >
                <h3 className="text-xl font-bold mb-2 main-title-gradient">
                  {section.title}
                </h3>
                {songBlocks
                  .filter(block => block.type === section.id)
                  .map((block, index) => (
                    <Draggable key={block.id} draggableId={block.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="relative p-3 rounded shadow mb-2 rap-fragment"
                        >
                          <button
                            onClick={() => handleDelete(block.id)}
                            className="supprimer-fragment"
                            aria-label="Supprimer"
                          >
                            ✕
                          </button>
                          <pre className="whitespace-pre-wrap pr-8 font-sans">{block.content}</pre>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </div>
  );
}

export default SongBuilder;