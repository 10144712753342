import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBwQqw-fQpey29XK8fYtMNsIRACaepnQ_8",
  authDomain: "rapgpt-f1e84.firebaseapp.com",
  projectId: "rapgpt-f1e84",
  storageBucket: "rapgpt-f1e84.appspot.com",
  messagingSenderId: "1064148484166",
  appId: "1:1064148484166:web:82242222222222222222"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };